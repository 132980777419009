@if(mode == 'reactive'){
<div class="my-md-3 my-2" [formGroup]="formGroup">
  <form-input-label [formGroup]="formGroup" [label]="label" [controlName]="controlName" />
  <p-dropdown [options]="groupedCities" placeholder="{{'selectFromList' | translate}}" [formControlName]="controlName"
    [ngClass]="{'is-invalid':isInvalid == true, 'submitted':isSubmitted}" [filter]="true" filterBy="labelAr,label"
    [emptyMessage]="'messages.noRecords' | translate" optionValue="value" [group]="true" appendTo="body"
    [emptyFilterMessage]="'messages.noRecords' | translate">
    <ng-template let-group pTemplate="group">
      <div class="flex align-items-center">
        <span>{{ group['label' | translate] }}</span>
      </div>
    </ng-template>
    <ng-template pTemplate="selectedItem" let-item>
      {{ item['label' | translate] }}
    </ng-template>

    <ng-template pTemplate="item" let-item>
      {{ item['label' | translate] }}
    </ng-template>

  </p-dropdown>

  <app-error-messages [controlName]="controlName" [form]="formGroup" [isSubmitted]="isSubmitted" />
</div>
} @else {
<p-dropdown [options]="groupedCities" [(ngModel)]="inputValue" (ngModelChange)="onInputChange($event)"
  placeholder="{{'selectFromList' | translate}}" [filter]="true" filterBy="labelAr,label"
  [ngClass]="{'is-invalid':isInvalid == true, 'submitted':isSubmitted}" [group]="true" appendTo="body"
  [emptyMessage]="'messages.noRecords' | translate" [emptyFilterMessage]="'messages.noRecords' | translate">
  <ng-template let-group pTemplate="group">
    <div class="flex align-items-center">
      <span>{{ group['label' | translate] }}</span>
    </div>
  </ng-template>
  <ng-template pTemplate="selectedItem" let-item>
    {{ item['label' | translate] }}
  </ng-template>

  <ng-template pTemplate="item" let-item>
    {{ item['label' | translate] }}
  </ng-template>

</p-dropdown>
}